import React from 'react';
import { ICON_SIZE_DEFAULT } from '../../../common';
import { AlertCard, AlertCardProps, AlertCardSizes, AlertCardVariant } from '../../AlertCard/AlertCard';
import { IconCatalog } from '../../Icon/Icon';

interface InfoStateProps extends Omit<AlertCardProps, 'icon' | 'variant' | 'body'> {
  /**
   * Set a description text
   */
  description?: string;
}

export const InfoState = ({
  description,
  size = AlertCardSizes.fluid,
  iconSize = ICON_SIZE_DEFAULT,
  ...props
}: InfoStateProps) => {
  return (
    <AlertCard
      {...props}
      size={size}
      body={description}
      iconSize={iconSize}
      icon={IconCatalog.infoFilled}
      variant={AlertCardVariant.info}
    />
  );
};
