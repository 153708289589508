import React from 'react';
import { SelectItem as Item, SelectItemText } from '@radix-ui/react-select';

export const SelectItem = React.forwardRef(
  ({ children, option, className, isFullWidth, classes, ...props }: any, forwardedRef) => {
    return (
      <Item className={classes.option(option)} {...props} ref={forwardedRef}>
        <SelectItemText>{children}</SelectItemText>
      </Item>
    );
  },
);

export default SelectItem;
