import React, { ChangeEvent, FocusEvent, useState } from 'react';
import cn from 'classnames';
import { setMaxHeightByOptions } from '../../../common/utils/helpers';
import { TextInput, TextInputProps } from '../TextInput/TextInput';
import { Popover, PopoverPlacement } from '../../Overlays/Popovers/Popover/Popover';

export interface TypeaheadProps<T> extends TextInputProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Specify an optional className to be added to the popover menu.
   */
  menuClassName?: string;

  /**
   * Set the options list.
   */
  options: Array<T>;

  /**
   * A callback function to set the option component from the host
   */
  renderOption: (option: T, index: number, onClose: () => void) => JSX.Element;
}

/**
 * Typeahead combines a text entry with a picker menu, allowing users to filter longer lists to only the selections matching a query.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-08-10
 */
export const Typeahead = <T,>({
  className,
  menuClassName,
  value,
  renderOption,
  options = [],
  onChange,
  onBlur,
  onFocus,
  ...restOfProps
}: TypeaheadProps<T>) => {
  const classes = {
    container: cn(className),
    popoverContent: cn(
      menuClassName,
      'e-scrollbar-w-2 e-scrollbar-thumb-neutral-400 e-scrollbar-track-neutral-700 e-scrollbar-thumb-rounded-lg',
      'e-flex-grow e-w-full e-overflow-y-auto',
    ),
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleInputClick = () => setIsOpen(true);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) onChange(event);
  };

  const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
    if (onBlur) onBlur(event);
  };

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
    setIsOpen(true);

    if (onFocus) onFocus(event);
  };

  const handleClickOutside = (): void => setIsOpen(false);

  const popupContent = (
    <div
      role="listbox"
      className={classes.popoverContent}
      style={setMaxHeightByOptions({ listLength: options.length, maxOptions: 3, maxHeight: '360px' })}>
      {options.map((item, index) => renderOption(item as T, index, handleClickOutside))}
    </div>
  );

  /* Render JSX */
  return (
    <Popover
      isOpen={isOpen}
      content={popupContent}
      placement={PopoverPlacement.bottomStart}
      onClickOutside={handleClickOutside}
      hasArrow={false}
      menuClassName={menuClassName}
      menuFullWidth>
      <TextInput
        className={classes.container}
        value={value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        onClick={handleInputClick}
        {...restOfProps}
      />
    </Popover>
  );
};
