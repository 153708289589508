import React from 'react';
import cn from 'classnames';
import { Icon, IconCatalog } from '../../../../Icon/Icon';

export interface RowItemProps {
  /**
   * The row id
   */
  itemId: string;

  /**
   * Elements to display inside the Modal.
   */
  children: React.ReactNode;

  /**
   * Specify an optional className to be added to the body section.
   */
  className?: string;

  /**
   * Whether the row item is selected or not
   */
  isSelected?: boolean;

  /**
   * Whether the row item is focused or not
   */
  isFocused?: boolean;

  /**
   * The callback to get notified when an item was clicked
   */
  onClick?: () => void;
}

/**
 * `RowItem` represents a row item of a modal body (ModalSearch)
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-10-16
 */
export const RowItem = React.forwardRef<HTMLDivElement, RowItemProps>(
  ({ className, itemId, children, isSelected = false, isFocused = false, onClick }, ref) => {
    const classes = {
      body: cn(
        'e-relative e-flex e-content-between e-w-full',
        'e-p-4 e-h-18',
        'e-cursor-pointer',
        'hover:e-bg-neutral-700 hover:e-rounded',
        className,
        {
          'e-border e-border-primary-800 e-bg-primary-900 e-rounded': isSelected,
          'e-border-x e-border-x-transparent e-border-t e-border-t-transparent e-border-b e-border-neutral-600':
            !isSelected && !isFocused,
          'e-border e-border-neutral-300 e-rounded': isFocused && !isSelected,
        },
      ),
      icon: cn('e-text-primary-300 e-mr-2'),
      chevronIcon: cn('e-text-primary-300 e-self-center e-justify-self-center e-ml-2'),
    };

    const handleClick = () => {
      if (onClick) onClick();
    };

    return (
      <div ref={ref} data-id={itemId} className={classes.body} onClick={handleClick}>
        {isSelected && <Icon className={classes.icon} icon={IconCatalog.check} width={24} height={24} />}
        {children}
        <Icon className={classes.chevronIcon} icon={IconCatalog.chevronRight} width={24} height={24} />
      </div>
    );
  },
);
