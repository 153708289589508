import React, { ChangeEvent, FocusEvent, InputHTMLAttributes, useEffect, useState } from 'react';
import cn from 'classnames';
import { FormFieldState } from '../../../common/constants';
import { Input } from '../../../common/types/input';
import { Icon, IconCatalog } from '../../Icon/Icon';
import { Popover, PopoverPlacement } from '../../Overlays/Popovers/Popover/Popover';

export enum BtnPosition {
  start = 'start',
  end = 'end',
}

type EmojiType = `&#${string}`;

export interface ComboBoxProps extends Input, Omit<InputHTMLAttributes<HTMLInputElement>, 'required'> {
  /**
   * Set the popover content to be displayed, when the user clicks on the button
   */
  popoverContent?: JSX.Element;

  /**
   * Whether the Menu is open
   */
  menuIsOpen?: boolean;

  /**
   * The comboBox Button to display on the start or end side.
   */
  btnPosition?: BtnPosition;

  /**
   * The icon to display within the Button.
   */
  iconBtn?: IconCatalog | EmojiType;

  /**
   * The Text to display within the Button.
   */
  textBtn?: string;

  /**
   * The icon to display within the TextInput.
   */
  iconContent?: IconCatalog;

  /**
   * Specify an optional className to be added to the popover menu.
   */
  menuClassName?: string;

  /**
   * The callback to get notified when the button was clicked.
   */
  onBtnClick?: () => void;

  /**
   * The callback to get notified when the user clicked outside.
   */
  onClickOutside?: () => void;
}

/**
 * Similar to the Select (Dropdown) but allowing the user to edit the input field in order to sort through the list of options.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-06-02
 */
export const ComboBox = React.forwardRef<HTMLInputElement, ComboBoxProps>(
  (
    {
      id,
      isDisabled = false,
      isRequired = false,
      isFullWidth = false,
      isReadOnly = false,
      isRounded = false,
      fieldState = FormFieldState.default,
      label,
      assistiveText,
      iconContent,
      iconBtn,
      textBtn,
      btnPosition,
      popoverContent,
      menuIsOpen = false,
      className,
      dataTestId,
      value = '',
      name,
      menuClassName,
      onChange,
      onBlur,
      onFocus,
      onBtnClick,
      onClickOutside,
      ...restOfProps
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isOpen, setIsOpen] = useState(menuIsOpen);
    const isComboboxActive = fieldState === FormFieldState.active && !isDisabled;
    useEffect(() => {
      setIsOpen(menuIsOpen);
    }, [menuIsOpen]);

    const ValidationStyle = {
      'e-border-neutral-400': fieldState === FormFieldState.default && !isDisabled,
      'e-border-error-500': fieldState === FormFieldState.error && !isDisabled,
      'e-border-primary-500': (fieldState === FormFieldState.default && isFocused) || isComboboxActive,
      'e-border-transparent': isDisabled,
    };

    const classes = {
      container: cn(className),
      comboBoxContainer: cn(
        'e-relative e-flex e-items-center e-overflow-hidden',
        'e-bg-neutral-700 e-text-neutral-50 e-border',
        'e-h-10',
        'e-rounded-lg',
        {
          'e-flex-row-reverse': btnPosition === BtnPosition.start,
          'e-w-full': isFullWidth,
          ...ValidationStyle,
        },
      ),
      button: cn('e-flex e-items-center e-p-2 e-cursor-pointer hover:e-bg-neutral-500 e-text-neutral-50', {
        'e-border-r': btnPosition === BtnPosition.start,
        'e-border-l': btnPosition === BtnPosition.end,
        ...ValidationStyle,
      }),
      inputContainer: cn(className, 'e-relative e-flex e-items-center e-p-2 e-w-full', {
        'e-flex-row-reverse': btnPosition === BtnPosition.end,
      }),
      input: cn(
        'e-w-full e-ml-2',
        'e-transition e-duration-100 e-ease-out e-outline-none',
        'placeholder:e-text-neutral-300 e-text-base e-font-regular',
        {
          'e-bg-neutral-700': !isDisabled,
          'e-bg-neutral-800': isDisabled,
        },
      ),
      assistiveText: cn('e-mt-2 e-text-xs e-font-extra-light', {
        'e-text-neutral-200': fieldState === FormFieldState.default,
        'e-text-error-500': fieldState === FormFieldState.error,
      }),
      iconContent: cn('e-flex e-items-center e-justify-center', {
        'e-ml-1': btnPosition === BtnPosition.start,
        'e-mr-1': btnPosition === BtnPosition.end,
      }),
    };

    const handleClickOutside = (): void => {
      setIsOpen(false);
      if (onClickOutside) onClickOutside();
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
      setIsOpen(false);
      if (onChange) onChange(event);
    };

    const handleInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
      setIsFocused(false);
      if (onBlur) onBlur(event);
    };

    const handleInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
      setIsFocused(true);
      setIsOpen(false);
      if (onFocus) onFocus(event);
    };

    const handleBtnClick = (): void => {
      if (popoverContent) setIsOpen(true);
      if (onBtnClick) onBtnClick();
    };

    /* Render JSX */
    return (
      <div className={classes.container}>
        {label && (
          <label className="e-mb-2 e-text-neutral-50 e-text-sm e-font-regular" htmlFor={id}>
            {label}
            {isRequired && '*'}
          </label>
        )}
        <div className={classes.comboBoxContainer}>
          <div className={classes.inputContainer}>
            <Popover
              isOpen={isOpen}
              content={popoverContent as JSX.Element}
              placement={PopoverPlacement.bottomStart}
              onClickOutside={handleClickOutside}
              hasArrow={false}
              menuClassName={menuClassName}>
              {/* MENU */}
              <div
                role="button"
                tabIndex={0}
                className={classes.button}
                onClick={handleBtnClick}
                onKeyDown={handleBtnClick}>
                {iconBtn?.includes('&#') ? (
                  <span>{iconBtn.replace('&#', '')}</span>
                ) : (
                  <Icon icon={iconBtn as IconCatalog} />
                )}
                <Icon icon={IconCatalog.caretDown} />
                {textBtn && <span className="e-text-base e-font-regular">{textBtn}</span>}
              </div>
            </Popover>
            {/* TEXT INPUT */}
            <input
              id={id}
              data-testid={dataTestId}
              ref={ref}
              name={name}
              className={classes.input}
              disabled={isDisabled}
              required={isRequired}
              value={value}
              readOnly={isReadOnly}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onFocus={handleInputFocus}
              {...restOfProps}
            />
            {iconContent && <Icon className={classes.iconContent} icon={iconContent} />}
          </div>
        </div>

        {assistiveText && <span className={classes.assistiveText}>{assistiveText}</span>}
      </div>
    );
  },
);
