import React from 'react';
import cn from 'classnames';
import { countryCodeEmoji } from 'country-code-emoji';
import { setMaxHeightByOptions } from '../../../common';

export type CountryCodeOption = {
  name: string;
  phoneCode: string;
  isoCode: string;
};

export interface CountryCodePopupMenuProps {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Set the default seelected option
   */
  selectedOption?: CountryCodeOption;

  /**
   * Country list
   */
  countryCodes?: Array<CountryCodeOption>;

  /**
   * Specify an optional test ID to use on e2e tests.
   */
  dataTestId?: string;

  /**
   * The callback to get notified when the button was clicked.
   */
  onOptionClick?: (selectedOption: CountryCodeOption) => void;
}

/**
 * This represent a list of Country codes. It's mostly used by the PhoneInput component
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-06-08
 */
export const CountryCodePopupMenu = ({
  className,
  dataTestId,
  selectedOption,
  countryCodes = [],
  onOptionClick,
}: CountryCodePopupMenuProps) => {
  const classes = {
    container: cn(
      className,
      'e-scrollbar-w-2 e-scrollbar-thumb-neutral-400 e-scrollbar-track-neutral-700 e-scrollbar-thumb-rounded-lg',
      'e-flex-grow e-w-full e-overflow-y-auto',
    ),
    option: (option: CountryCodeOption) =>
      cn('e-flex e-items-center e-space-x-3', 'e-px-3 e-py-4', 'hover:e-bg-neutral-700', {
        'e-bg-neutral-700': selectedOption?.isoCode === option.isoCode,
      }),
  };

  const handleOptionClick = (option: CountryCodeOption) => () => {
    if (onOptionClick) onOptionClick(option);
  };

  const renderOptions = () => {
    return countryCodes.map((option, index) => (
      <div
        key={index}
        role="option"
        tabIndex={-1}
        className={classes.option(option)}
        aria-selected={selectedOption?.isoCode === option.isoCode}
        onClick={handleOptionClick(option)}
        onKeyPress={handleOptionClick(option)}>
        <div>{countryCodeEmoji(option.isoCode)}</div>
        <div className="e-flex e-w-full e-text-sm e-text-neutral-50 e-font-medium">{option.name}</div>
        <div className="e-text-sm e-text-neutral-50 e-font-medium e-ml-auto">+{option.phoneCode}</div>
      </div>
    ));
  };

  /* Render JSX */
  return (
    <div
      data-testid={dataTestId}
      role="listbox"
      style={setMaxHeightByOptions({ listLength: countryCodes.length, maxOptions: 6, maxHeight: '210px' })}
      className={classes.container}>
      {renderOptions()}
    </div>
  );
};
