import React, { ChangeEvent, InputHTMLAttributes, useEffect, useRef } from 'react';
import cn from 'classnames';
import { createUseStyles } from 'react-jss';
import { Input } from '../../../common/types/input';
import { Colors } from '../../../common';

export interface CheckboxProps extends Input, Omit<InputHTMLAttributes<HTMLInputElement>, 'onClick'> {
  /**
   * Specify an optional className to be added to the input tag
   */
  inputClassName?: string;

  /**
   * Specify if it's checked or not
   */
  checked?: boolean;

  /**
   * Whether the Checkbox is Indeterminate or not.
   */
  indeterminate?: boolean;

  /**
   * Hex color of the checkbox
   */
  color?: typeof Colors[number];

  /**
   * Provide a handler that is called whenever the container is clicked
   */
  onClick?: (event: React.MouseEvent) => void;
}

// React with JSS
const useCheckboxStyles = createUseStyles({
  checkbox: {
    '&:checked': {
      backgroundColor: (props: CheckboxProps) => props.color,
    },
  },
});

/**
 * Checkbox are used when there are multiple items to select in a list. Users can select zero, one, or any number of items.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-05-26
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id,
      name,
      label,
      value,
      className,
      inputClassName,
      checked,
      isDisabled = false,
      onChange,
      onClick,
      color,
      indeterminate,
      ...restOfProps
    },
    ref,
  ) => {
    const useStyles = useCheckboxStyles({ color });

    const classes = {
      container: cn(className, 'e-flex e-items-center', {
        'e-cursor-pointer': !isDisabled,
      }),
      input: cn(
        inputClassName,
        'checked:e-bg-[url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMTYgMTYnIGZpbGw9JyNmZmYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTEyLjIwNyA0Ljc5M2ExIDEgMCAwIDEgMCAxLjQxNGwtNSA1YTEgMSAwIDAgMS0xLjQxNCAwbC0yLTJhMSAxIDAgMCAxIDEuNDE0LTEuNDE0TDYuNSA5LjA4Nmw0LjI5My00LjI5M2ExIDEgMCAwIDEgMS40MTQgMHonLz48L3N2Zz4=)]',
        'checked:e-bg-[position:50%] checked:e-bg-[length:120%_120%] checked:e-bg-no-repeat',
        `checked:e-border-transparent`,
        // Indeterminate
        'indeterminate:e-bg-[url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgOCAyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTAgMS4wMDA2NUMwIDAuNjMyNDYxIDAuMjk4NDc3IDAuMzMzOTg0IDAuNjY2NjY3IDAuMzMzOTg0SDcuMzMzMzNDNy43MDE1MiAwLjMzMzk4NCA4IDAuNjMyNDYxIDggMS4wMDA2NUM4IDEuMzY4ODQgNy43MDE1MiAxLjY2NzMyIDcuMzMzMzMgMS42NjczMkgwLjY2NjY2N0MwLjI5ODQ3NyAxLjY2NzMyIDAgMS4zNjg4NCAwIDEuMDAwNjVaIiBmaWxsPSIjRUVFRkVGIi8+Cjwvc3ZnPgo=)]',
        'indeterminate:e-bg-[position:50%_50%] indeterminate:e-bg-no-repeat checked:e-bg-[length:100%_100%]',
        'indeterminate:e-border-transparent indeterminate:e-bg-primary-500',
        'e-border-2 e-border-neutral-50 e-rounded',
        'e-min-w-4 e-h-4',
        'focus:e-outline-none e-appearance-none',
        {
          'e-opacity-60 e-pointer-events-none': isDisabled,
          'checked:e-bg-primary-500': !color,
          [useStyles.checkbox]: checked && color,
        },
      ),
      label: cn('e-ml-2 e-font-medium e-text-neutral-50 e-text-sm', {
        'e-opacity-60 e-pointer-events-none': isDisabled,
      }),
    };

    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;

    useEffect(() => {
      if (!resolvedRef.current) return;
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    const handleClick = (event: React.MouseEvent) => {
      if (onClick) onClick(event);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (isDisabled) return;
      if (onChange) onChange(event);
    };

    return (
      <label htmlFor={id} className={classes.container} onClick={handleClick}>
        <input
          id={id}
          ref={resolvedRef}
          type="checkbox"
          name={name}
          className={classes.input}
          checked={checked}
          value={value}
          onChange={handleChange}
          disabled={isDisabled}
          {...restOfProps}
        />
        {label && <span className={classes.label}>{label}</span>}
      </label>
    );
  },
);
