import React from 'react';
import { AlertCard, AlertCardProps, AlertCardSizes, AlertCardVariant } from '../../AlertCard/AlertCard';

const ICON_SIZE = 64;

interface EmptyStateProps extends Omit<AlertCardProps, 'variant' | 'body'> {
  /**
   * Set a description text
   */
  description?: string;
}

/**
 * This component is used for presenting various empty states, such as no items, empty search, broken link, welcome screen, etc. They are most commonly seen the first time a user interacts with a product or page, but can be used when data has been deleted or is unavailable. An empty state is an opportunity to engage and delight users adding an actionable button.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-07-28
 */
export const EmptyState = ({
  description,
  size = AlertCardSizes.fluid,
  iconSize = ICON_SIZE,
  ...props
}: EmptyStateProps) => {
  return <AlertCard {...props} size={size} iconSize={iconSize} variant={AlertCardVariant.neutral} body={description} />;
};
