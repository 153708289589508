import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { countryCodeEmoji } from 'country-code-emoji';
import { ComboBox, ComboBoxProps, BtnPosition } from '../ComboBox/ComboBox';
import { CountryCodePopupMenu, CountryCodeOption } from '../../PopupMenus/CountryCodePopupMenu/CountryCodePopupMenu';

export type PhoneObjType = {
  phone: string;
  phoneCode: string;
  isoCode?: string;
};

export interface PhoneInputProps extends ComboBoxProps {
  /**
   * Country list
   */
  countryCodes?: Array<CountryCodeOption>;

  /**
   * Set the default selected country.
   */
  defaultCountryCode: string;

  /**
   * Set the ISO code for the selected country
   */
  defaultCountryIsoCode?: string;

  /**
   * Specify an optional className to be added to the popover menu.
   */
  menuClassName?: string;

  /**
   * Callback to handle input change.
   */
  onInputChange: ({ phone, phoneCode }: PhoneObjType) => void;
}

/**
 * The Phone Input is used to collect phone numbers with international dial codes. It contains a dropdown which allows the user to choose from a list of countries and dial codes.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2022-06-08
 */
export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
  (
    {
      className,
      value = '',
      defaultCountryCode,
      defaultCountryIsoCode,
      menuClassName,
      countryCodes = [],
      onChange,
      onBtnClick,
      onInputChange,
      ...restOfProps
    },
    ref,
  ) => {
    const classes = {
      container: cn(className),
      menu: cn(menuClassName, 'e-min-w-96 e-overflow-hidden'),
    };
    const defaultCountryOption = useMemo(
      () =>
        countryCodes.find((option) => {
          const isPhoneCodeMatch = option.phoneCode === defaultCountryCode;
          if (!defaultCountryIsoCode) return isPhoneCodeMatch;
          // Compare against the isoCode if its provided
          return isPhoneCodeMatch && option.isoCode === defaultCountryIsoCode;
        }),
      [countryCodes, defaultCountryIsoCode],
    );
    const [selectedCountry, setSelectedCountry] = useState<CountryCodeOption | undefined>(defaultCountryOption);
    const [inputValue, setInputValue] = useState(value);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const parsePhoneNumber = (phoneNumber: string) => phoneNumber.replace(/\D/g, '');

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    useEffect(() => {
      setSelectedCountry(defaultCountryOption);
    }, [defaultCountryCode, defaultCountryIsoCode, countryCodes]);

    const handleOptionClick = (option: CountryCodeOption) => {
      setSelectedCountry(option);
      setMenuIsOpen(false);
      if (onInputChange)
        onInputChange({
          phone: inputValue as string,
          phoneCode: option.phoneCode,
          isoCode: option?.isoCode,
        });
    };

    const handleBtnClick = () => {
      setMenuIsOpen(!menuIsOpen);
      if (onBtnClick) onBtnClick();
    };

    const handleClickOutside = () => setMenuIsOpen(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      const parsedPhone = parsePhoneNumber(value);
      setInputValue(parsedPhone);
      if (onInputChange)
        onInputChange({
          phone: parsedPhone,
          phoneCode: selectedCountry?.phoneCode ?? '',
          isoCode: selectedCountry?.isoCode ?? '',
        });
    };

    const iconContent = () => {
      if (!selectedCountry?.isoCode) return '🟣';
      return countryCodeEmoji(selectedCountry?.isoCode ?? '');
    };

    /* Render JSX */
    return (
      <ComboBox
        className={classes.container}
        ref={ref}
        value={inputValue}
        popoverContent={
          <CountryCodePopupMenu
            countryCodes={countryCodes}
            onOptionClick={handleOptionClick}
            selectedOption={selectedCountry}
          />
        }
        onChange={handleChange}
        onBtnClick={handleBtnClick}
        onClickOutside={handleClickOutside}
        iconBtn={`&#${iconContent()}`}
        textBtn={selectedCountry?.phoneCode ? `+${selectedCountry?.phoneCode}` : '-'}
        btnPosition={BtnPosition.start}
        menuIsOpen={menuIsOpen}
        menuClassName={classes.menu}
        {...restOfProps}
      />
    );
  },
);
