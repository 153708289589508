import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import cn from 'classnames';
import { Input } from '../../../common/types/input';

export enum SwitchSize {
  sm = 'sm',
}

export interface SwitchProps extends Input, Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'value' | 'onChange'> {
  /**
   * Initial value for the Switch.
   */
  value?: boolean;

  /**
   * Changes the size of the Switch.
   */
  size?: SwitchSize;

  /**
   * Provide a handler that is called whenever <input> is updated
   */
  onChange?: (checked: boolean) => void;
}

/**
 * Switch represents a physical switch that allows users to turn things on or off, where choosing an option results in an immediate action.
 * @author Sergio Ruiz Davila<sergioruizdavila@gmail.com>
 * Created at 2022-08-10
 */
export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(
  (
    { id, name, value = false, label, size = SwitchSize.sm, isDisabled = false, className, onChange, ...restOfProps },
    ref,
  ) => {
    const [isOn, setIsOn] = useState(value);

    const classes = {
      container: cn(className, 'e-flex e-items-center'),
      inputContainer: cn('e-relative', {
        'e-w-10 e-h-6': size === SwitchSize.sm,
        'e-opacity-60 e-pointer-events-none': isDisabled,
      }),
      input: cn('e-absolute e-z-10 e-opacity-0', {
        'e-w-10 e-h-6': SwitchSize.sm,
        'e-cursor-pointer': !isDisabled,
      }),
      inner: cn('e-absolute e-top-0', {
        'e-w-10 e-h-6 e-rounded-2xl': size === SwitchSize.sm,
        'e-bg-neutral-200': !isOn,
        'e-bg-primary-500': isOn,
      }),
      thumb: cn('e-rounded-full e-bg-base-white e-absolute e-shadow-sm', 'e-transition-all e-duration-100 e-ease-in', {
        'e-w-4 e-h-4 e-left-1 e-top-1': size === SwitchSize.sm,
        'e-transform e-translate-x-4': isOn,
      }),
    };

    // Update value if default value changes
    useEffect(() => {
      setIsOn(value);
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      if (isDisabled) return;

      const checked = event?.target?.checked;
      setIsOn(checked);
      if (onChange) onChange(checked);
    };

    return (
      <div className={classes.container}>
        <div className={classes.inputContainer}>
          <input
            id={id}
            ref={ref}
            className={classes.input}
            name={name}
            role="switch"
            type="checkbox"
            checked={isOn}
            aria-checked={isOn}
            disabled={isDisabled}
            onChange={handleChange}
            {...restOfProps}></input>
          <div className={classes.inner}>
            <div className={classes.thumb}></div>
          </div>
        </div>
        {label && (
          <label htmlFor={id} className="e-ml-3 e-text-sm e-font-medium e-text-neutral-50">
            {label}
          </label>
        )}
      </div>
    );
  },
);
