import React, { ReactNode, ReactElement } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import cn from 'classnames';
import { ModalSearchHeaderProps } from '../ModalContent/ModalSearchHeader';
import { ModalListBodyProps } from '../ModalContent/ModalListBody';
import { ModalFooterProps } from '../ModalContent/ModalFooter';
import { ModalContent, ModalSize } from '../ModalContent';
import { RowItemProps, RowItemSkeleton } from './RowItem';

export interface SearchModalProps extends Omit<ModalListBodyProps, 'children'>, Omit<ModalFooterProps, 'children'> {
  /**
   * Specify an optional className to be added to the component
   */
  className?: string;

  /**
   * Specify an optional test ID to use on e2e tests.
   */
  dataTestId?: string;

  /**
   * Define the modal header
   */
  header?: Omit<ModalSearchHeaderProps, 'onCloseBtnClick'>;

  /**
   * Elements to display inside the Modal.
   */
  body: Array<ReactElement<RowItemProps>> | ReactElement;

  /**
   * Elements to display inside the Footer.
   */
  footer?: ReactNode;

  /**
   * Changes the size of the modal, giving it more or less padding
   */
  size?: ModalSize;

  /**
   * Whether the list has more results to load
   */
  hasMoreResults?: boolean;

  /**
   * Whether the list has more results to load
   */
  isFetchingMore?: boolean;

  /**
   * Whether the list of items is loading or not
   */
  onItemClick?: (itemId: string) => void;

  /**
   * The callback to get notified when the modal was closed
   */
  onClose?: () => void;

  /**
   * The callback to get notified when the list is scrolled to the bottom
   */
  onLoadMore?: () => void;
}

/**
 * This is a modal with a search input and a list of items to select from.
 * @author Sergio Ruiz<sergioruizdavila@gmail.com>
 * Created at 2023-10-11
 */
export const SearchModal = ({
  className,
  dataTestId,
  header,
  body,
  footer,
  size = ModalSize.lg,
  showSkeletons = false,
  hasMoreResults = false,
  isFetchingMore = false,
  onItemClick,
  onClose,
  onLoadMore = () => {},
}: SearchModalProps) => {
  const classes = cn('e-h-5/6', className);
  const showInnerSpinner = isFetchingMore || hasMoreResults;

  const [paginatedResultRef] = useInfiniteScroll({
    onLoadMore,
    hasNextPage: hasMoreResults,
    loading: isFetchingMore,
  });

  const handleCancelBtnClick = () => {
    if (onClose) onClose();
  };

  const handleItemClick = (itemId: string) => {
    if (onItemClick) onItemClick(itemId);
  };

  const renderBody = () => {
    const loadingElement = (
      <div ref={paginatedResultRef} className="e-flex e-flex-col">
        {Array.from({ length: 3 }).map((_, i) => (
          <RowItemSkeleton key={i} />
        ))}
      </div>
    );

    if (!Array.isArray(body)) {
      return <ModalContent.Body>{body}</ModalContent.Body>;
    } else {
      return (
        <ModalContent.ListBody
          showSkeletons={showSkeletons}
          loadingElement={showInnerSpinner ? loadingElement : null}
          onItemClick={handleItemClick}>
          {body}
        </ModalContent.ListBody>
      );
    }
  };

  /* Render JSX */
  return (
    <ModalContent data-testid={dataTestId} className={classes} size={size} onEscKeyPress={handleCancelBtnClick}>
      {/* HEADER */}
      {header && <ModalContent.SearchHeader {...header} onCloseBtnClick={handleCancelBtnClick} />}

      {/* BODY */}
      {renderBody()}

      {/* FOOTER */}
      <ModalContent.Footer>{footer}</ModalContent.Footer>
    </ModalContent>
  );
};
